<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <div class="bpp-post-content">
          <p><img loading="lazy" class="aligncenter" title="【案例分析】回老家还是去希腊？这不是个问题！" src="@/assets/Cases/15/1.jpg" alt="【案例分析】回老家还是去希腊？这不是个问题！" width="662" height="368"><br>
            <strong>自述</strong></p>
          <p>我在上海一家大型的4A广告公司担任中层职位，日常工作繁忙，这个行业注定加班是家常便饭。今年年初，我正在公司彻夜奋战之时，圈里的朋友给我转发了一篇热文，叫《卖掉北京500万的房，回老家生活的这两年……》讲的是作者卖掉现在北京价值500万的房产，逃离一线城市回归家乡，想拥抱诗和远方，结果 后又逃回北京的故事。初一看内容我不禁哑然失笑，但是细细一品，又觉得意味深长。我们到底有多少存款，才能在这个一线城市过好这一生？<strong>而且上海的生活成本，比北京还高。到了我这个岁数，身边的朋友很多都通过创业或者早年投资，过上了更好的生活。</strong></p>
          <p>2015年我也尝试过炒股，但 后发现自己不是这块料，炒股更像是投机赌博，拼的是一个心态，你总是想抄底，却发现自以为到底了，然而地下还有十八层地狱。我不仅钱没赚到，反而还遇到了股灾。</p>
          <p><img loading="lazy" class="aligncenter" title="【案例分析】回老家还是去希腊？这不是个问题！" src="@/assets/Cases/15/2.jpg" alt="【案例分析】回老家还是去希腊？这不是个问题！" width="599" height="399"></p>
          <p><strong>萌芽</strong></p>
          <p><strong>一个偶然的机会我从朋友那里了解到，现在<a href="/greekzt">希腊移民</a>很火爆，政策宽松，生活环境优异。</strong>而且欧洲经济复苏向好，许多投资人士去希腊购置房产做短租服务。再加上年轻的时候和老婆度蜜月，我们去的就是希腊的圣托里尼和雅典，回来后老婆一直念念不忘，希腊蔚蓝的天空，白色的房子在我们年轻的心里留下了深刻的记忆。<strong>于是逐渐的，我开始产生了<a href="/greekzt">移民希腊</a>的想法。</strong></p>
          <p><strong>跃迁出国的移民顾问告诉我，目前在希腊投资房产，不但能自住还能出租收租金，最重要的是一家三代人都可以拿到欧盟绿卡。而且希腊的国际学校又多学费又比国内便宜，还能直通欧美名校，孩子上学的问题解决了。比起在国内冒风险炒股投资，在希腊买房既能养老，还能赚钱，将来还有欧盟绿卡作为出行保障，一举三得。</strong>。</p>
          <p>后来在跃迁出国的安排下，我和老婆一起重回故地，在希腊进行了为期一周的房产考察。看了很多套房产以后，我们决定定下一套位于雅典市中心的房产，花费40万欧不到。之所以决定买这套房子是看中它地理位置靠近地铁，出行方便，而且周围有公园、医院、商超，生活措施一应俱全，不论是自住还是出租都是不错的选择。
          </p>
          <p><img loading="lazy" class="aligncenter" title="【案例分析】回老家还是去希腊？这不是个问题！" src="@/assets/house/3/7.jpg" alt="【案例分析】回老家还是去希腊？这不是个问题！" width="599" height="399"></p>
          <p><img loading="lazy" class="aligncenter" title="【案例分析】回老家还是去希腊？这不是个问题！" src="@/assets/house/3/8.jpg" alt="【案例分析】回老家还是去希腊？这不是个问题！" width="599" height="399"></p>
          <p>
            在当地支付了定金后，我跟老婆就回国了。接下来的几个月，我们在安排国内工作和家庭事务的间隙简单地准备了一些文件给顾问，剩下的工作全部交给了跃迁和移民律师。目前我们还在国内做出国的准备，打算在2025年全家搬迁到希腊生活。现在，我一边收着欧元房租，一边畅想着将来地中海的美好生活，连工作也变得不那么讨厌了。
          </p>
        </div>
      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases16",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>